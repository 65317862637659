/// /// converts an interaction json to a building-blocks-tabs tab json
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
export function convertInteractionToTab(interaction) {
    var _interaction_content;
    return {
        tabLabel: interaction === null || interaction === void 0 ? void 0 : interaction.label,
        tabIcon: interaction === null || interaction === void 0 ? void 0 : interaction.icon,
        textAndAsset: _object_spread_props(_object_spread({}, DEFAULT_TEXT_AND_ASSET_PARAMS), {
            fullParagraph: (_interaction_content = interaction === null || interaction === void 0 ? void 0 : interaction.content) !== null && _interaction_content !== void 0 ? _interaction_content : EMPTY_CONTENT_OBJECT,
            asset: (interaction === null || interaction === void 0 ? void 0 : interaction.actionAsset) || {}
        })
    };
}
const DEFAULT_TEXT_AND_ASSET_PARAMS = {
    contentClassName: "",
    swapTextAndAssetPosition: false,
    leftSizeWidth: null,
    rightSizeWidth: null,
    background: {},
    assetQuality: "",
    withDynamicParagraphTitle: false,
    withDynamicParagraphSubtitle: false,
    paddingTop: "",
    paddingBottom: "",
    titleSize: "",
    textSize: ""
};
const EMPTY_CONTENT_OBJECT = {
    topic: {},
    title: "",
    body: "",
    textAlign: "",
    mobileTextAlign: "",
    titleColor: "",
    buttonConfig: {}
};
