import styled from "@emotion/styled";
import { SILVER_MIST_COLOR_NAME, CHARCOAL_GRAY_COLOR_NAME } from "/styles/color-consts";
import Colors from "/styles/colors";
export const StyledPlatformInteractiveExperienceHomepageFirstFoldMobileComponent = styled.div`
  &.platform-interactive-experience-homepage-first-fold-mobile-component {
    .slick-slider.focus .slick-list .slick-track .slick-slide .carousel-item {
      opacity: 1;
    }

    .side-by-side-text-and-asset .image-wrapper {
      padding: 0 5px 0 !important;
    }

    .mobile-tabs-component .mobile-tabs-navigation {
      padding-bottom: 20px;

      .tab-nav-button {
        color: ${Colors[SILVER_MIST_COLOR_NAME]};
        background-color: black;
        border: ${Colors[CHARCOAL_GRAY_COLOR_NAME]} 1px solid;

        &.selected {
          color: white;
          border-color: ${Colors[SILVER_MIST_COLOR_NAME]};
        }
      }
    }
  }
`;
