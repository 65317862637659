import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledNavMenuComponent = styled.div`
  margin-bottom: 28px;

  .interaction-tags-container {
    display: flex;
    gap: 16px;
    font-size: 0.875rem;
    overflow-x: auto;

    -webkit-mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 93%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 93%, rgba(0, 0, 0, 0) 100%);

    .interaction-tag {
      cursor: pointer;
      border: 1px solid #ffffff30;
      border-radius: 50px;
      color: #b6b6bb;
      background-color: #0b0b1c;
      padding: 8px 18px;
      transition: all 0.3s;
      text-wrap: nowrap;

      :hover:not(.active) {
        background-color: #282852;
      }

      &.active {
        color: white;
        border-color: #b6b6bb;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    .interaction-tags-container {
      gap: 8px;
      font-size: 0.75rem;
    }
  }
`;
