import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { ZERO, LAYER_ONE, LAYER_TWO } from "constants/z-index";
import { INTERACTION_MODE_CHANGE_ANIMATION_DURATION } from "../../consts";
export const StyledPlatformInteractiveExperienceHomepageFirstFoldComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  height: ${({ segmentHeight })=>segmentHeight !== null && segmentHeight !== void 0 ? segmentHeight : "40vw"};
  ${({ segmentMaxHeight })=>segmentMaxHeight ? `max-height: ${segmentMaxHeight};` : ""}
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .info-section-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 26vw;
    max-width: 550px;
    z-index: ${LAYER_ONE};

    .info-section {
      padding-top: 8px;
      padding-left: 32px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      opacity: 0;
      transition: opacity 1s;

      .back-botton {
        cursor: pointer;
        font-size: 0.875rem;
        position: relative;
        margin-left: 10px;

        .back-arrow {
          position: absolute;
          left: -10px;
          transition: transform 0.5s;
        }

        &:hover {
          .back-arrow {
            transform: translateX(-5px);
            transition: transform 0.5s;
          }
        }
      }

      .info-content-wrapper {
        position: relative;

        .info-content {
          position: absolute;
          width: 100%;
          top: 0;
          left: -100%;
          display: flex;
          flex-direction: column;
          gap: 30px;
          opacity: 0;
          transition: all 1s;

          h2 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            transition: all 1s;
          }

          .paragraph-body {
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 300;
            transition: all 1s;
          }

          &.active {
            left: 0;
            opacity: 1;
            transition: all 1s;
          }
        }
      }

      &.active {
        opacity: 1;
        transition: opacity 1s;
      }
    }
  }

  .interactive-section-wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    transition: left 0.5s ease-in-out;
    display: flex;
    justify-content: center;

    .interactive-section {
      position: relative;
      width: ${({ backgroundSize })=>backgroundSize !== null && backgroundSize !== void 0 ? backgroundSize : "60vw"};
      max-width: 1100px;
      left: ${({ isInteractionActive })=>isInteractionActive ? "min(22vw,550px)" : "0"};
      transition: left 0.5s ease-in-out;

      .glow {
        position: absolute;
        width: ${({ glowWidth })=>glowWidth !== null && glowWidth !== void 0 ? glowWidth : "30vw"};
        top: ${({ glowPositionTop })=>glowPositionTop !== null && glowPositionTop !== void 0 ? glowPositionTop : "0"};
        left: ${({ glowPositionLeft })=>glowPositionLeft !== null && glowPositionLeft !== void 0 ? glowPositionLeft : "0"};
        z-index: ${ZERO};
      }

      .system-layout-wrapper {
        position: relative;
        width: 100%;
        z-index: ${LAYER_ONE};

        .interactions-nav-menu {
          opacity: 0;
          transition: opacity ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;

          &.active {
            opacity: 1;
            transition: opacity ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;
          }
        }

        .system-layout {
          position: relative;

          .system-action-wrapper {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;

            &.show {
              animation: appear ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s forwards;
            }

            &.hide {
              animation: dissapear ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s forwards;
            }
          }
        }
      }

      .platform-interaction {
        position: absolute;
        z-index: ${LAYER_TWO};
        opacity: 1;
        transition: opacity 1s, top ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s,
          left ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;

        .interaction-wrapper {
          cursor: pointer;
        }

        // when the interactions are hidden we have to give them display none because we interacte with elements behind them.
        // we have the "show" class so we can add back the display block but make them appear with animation

        &.show {
          animation: appear ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s forwards;
        }

        &.hide {
          animation: dissapear ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s forwards;

          .interaction-wrapper {
            cursor: default;
          }
        }
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    .info-section-wrapper {
      width: 32vw;
    }
  }

  @keyframes change-position-value {
    0% {
      position: absolute;
    }
    100% {
      position: static;
    }
  }

  @keyframes appear {
    0% {
      display: block;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dissapear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;
