import styled from "@emotion/styled";
import { BRAND_WORK_MANAGEMENT_PRIMARY_COLOR } from "styles/color-consts";
import colors from "styles/colors";
import { INTERACTION_MODE_CHANGE_ANIMATION_DURATION, INTERACTION_HOVER_ANIMATION_DURATION, RIPPLE_DEFAULT_ANIMATION_DURATION } from "../../consts";
export const ICON_MODE_SIZE = "100px";
export const StyledPlatformInteractionComponent = styled.div`
  position: absolute;
  top: ${({ position })=>{
    var _position_top;
    return (_position_top = position === null || position === void 0 ? void 0 : position.top) !== null && _position_top !== void 0 ? _position_top : "0";
}};
  left: ${({ position })=>{
    var _position_left;
    return (_position_left = position === null || position === void 0 ? void 0 : position.left) !== null && _position_left !== void 0 ? _position_left : "0";
}};

  .interaction-wrapper {
    background-color: #232446;
    border-radius: 4px;
    border: 1px solid #505050;
    width: ${({ dimentions })=>dimentions === null || dimentions === void 0 ? void 0 : dimentions.width};
    transition: all 0.5s;

    .image-mode-container {
      position: relative;
      pointer-events: none;
      user-select: none;
      opacity: 1;
      z-index: 1;
      transition: opacity ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;

      img {
        transition: width ${INTERACTION_MODE_CHANGE_ANIMATION_DURATION}s;
      }
    }

    .ripple-container {
      opacity: 1;
      transition: opacity ${INTERACTION_HOVER_ANIMATION_DURATION}s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .ripple-border {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border: 1px solid ${colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]};
        border-radius: 4px;
        height: 95%;
        width: 95%;
        z-index: 0;
        pointer-events: none;
        user-select: none;
      }

      .ripple-one {
        animation: ripple_animation
          ${({ rippleEffectAnimationDuration })=>rippleEffectAnimationDuration > 0 ? rippleEffectAnimationDuration : RIPPLE_DEFAULT_ANIMATION_DURATION}s
          linear 0s infinite;
      }

      .ripple-two {
        animation: ripple_animation
          ${({ rippleEffectAnimationDuration })=>rippleEffectAnimationDuration > 0 ? rippleEffectAnimationDuration : RIPPLE_DEFAULT_ANIMATION_DURATION}s
          linear 1s infinite;
      }
    }
  }

  .interaction-wrapper:hover {
    scale: 1.1;
    transition: scale ${INTERACTION_HOVER_ANIMATION_DURATION}s;

    .ripple-container {
      opacity: 0;
      transition: opacity ${INTERACTION_HOVER_ANIMATION_DURATION}s;
    }
  }

  @keyframes ripple_animation {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    5% {
      transform: scale(1.1);
      opacity: 1;
    }
    35% {
      opacity: 0.4;
    }
    50% {
      transform: scale(1.4);
      opacity: 0;
    }
    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
`;
